<template>
    <div class="clocklist">
        <div class="clocklist-list" v-for="(item,index) in list" :key="index">
            <div>{{item.session}}</div>
            <div>
                <p :style="item.awardStatus===0?'color:rgba(153, 153, 153, 1);':''">{{item.status===0?'打卡失败':item.status===1?'打卡成功':'尚未结束'}}</p>
                <p :style="item.awardStatus===0?'color:rgba(153, 153, 153, 1);':''">报名{{item.payAmount}}元</p>
                <p><span :style="item.awardStatus===1?'color: #FF5675;font-size:20px;':item.awardStatus===0?'color:rgba(153, 153, 153, 1);font-size:16px;':'font-size:16px;'">{{ item.awardStatus===1?'+'+item.awardAmount:item.awardStatus===0?'奖励无法瓜分':'奖励尚未结束' }}</span> <span v-if="item.awardStatus===1"  :style="item.awardStatus===1?'color: #FF5675;':''">元</span></p>
            </div>
        </div>
        <!-- <div class="clocklist-list">
            <div>
                <p class="color-red">打卡成功</p>
                <p>09-01 15:30</p>
            </div>
            <div><span>+1.66</span> 元</div>
        </div> -->
        <div class="notrecord" v-if="list.length<1">
            <img src="https://ppyos.xijiuyou.com/202304/sign-icon.png" alt="" />
            <p>还未参与打卡的记录噢</p>
        </div>
    </div>
</template>
<script>
import clockin from "../../api/clockin";
export default{
    data(){
        return {
            page:1,
            pageSize:20,
            list:[],
        }
    },
    mounted(){
        const that = this;
        this.morningrecord();
        window.addEventListener("scroll",function(){
      //加载更多
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
        //写后台加载数据的函数
        console.log(1)
        that.page ++;
        this.morningrecord();
      }
    });
    },
    methods:{
        morningrecord(){
            clockin.morningrecord({page:this.page,pageSize:this.pageSize}).then(res=>{
                this.list.push(...res.list)
            })
        },
    }
}
</script>
<style lang="less" scoped>
.clocklist{
    width: 100%;
    min-height: 100vh;
    background: #ffffff;
    padding: 0 12px;
    .clocklist-list{
        width: 100%;
        border-bottom: 1px solid #eeeeee;
        padding: 16px 0;
        box-sizing: border-box;
        div:nth-child(1){
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
        div:nth-child(2){
            display: flex;
            justify-content: space-between;
            align-items:baseline;
            p:nth-child(3){
                width: 100px;
                text-align: right;
            }
        }
    }
    .notrecord{
        width: 100%;
        padding-top:143px;
        text-align: center;
        img{
            width: 98px;
            margin-bottom: 24px;
        }
        p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>